








































































import { Component, Vue } from 'vue-property-decorator';
import { getProfileById, profiles } from '@/observables/profiles';
import { setPortfolioInView, activePortfolio, getters, setPorfolioType, modelData,getChangedFromStandard  } from '@/observables/portfolios';
import { setSlideOutByKey } from '@/observables/ui';
import InfoIcon from '@/components/UI/InfoIcon.vue';
import CustomCard from '@/components/UI/CustomCard.vue';
import { tierInfo } from '@/data/infoTextContent';
import { pathwaysInfo } from '@/data/infoTextContent';

@Component({
  components: {
    CustomCard,
    InfoIcon,
  },
})
export default class ProfileDetails extends Vue {
  public profileChanged = false;
  public tierInfo = tierInfo;
  public pathwaysInfo = pathwaysInfo;

  get scores() {
    return [
      {
        title: 'Your Overall Climate Score',
        score: this.teirResults.currentScore,
        popOverContent: `
      <div class="info-heading">
              <h2>Your overall climate score</h2>
              </div>
      <p>This is a score out of 5 representing the weighted average of the climate risk tiers of your assets, based on broad market indices only.</p> <p>This is an initial indication of how your portfolio is positioned relative to climate risk.</p>`,
      },
      {
        title: 'The Average Pension Fund Score',
        score: this.modelData.averagePensionFundScore,
        popOverContent: `
      <div class="info-heading">
              <h2>The average pension fund score</h2>
              </div>
      <p>This box shows an indicative score, for an average UK pension fund, that would be achieve based on a representative asset allocation.  To determin this, we have used an LCP visualise data set and the PPF Purple book 2020. For more information see <a target="_blank" href="/pdf/The_tip_of_the_iceberg_- _ow_UK_institutional_investors_are_responding_to_climate_risks.pdf">Tip of the Iceberg</a>.</p>`,
      },
      {
        title: 'Score You Could Achieve',
        score: this.teirResults.potentialScore,
        popOverContent: `
        <div class="info-heading">
              <h2>Score you could achieve</h2>
              </div>
      <p>This shows the score you could achieve if you moved key asset classes into Net Zero aligned mandates.</p>`,
      },
    ];
  }

  created(): void {
    this.getAndSetProfile();
  }

  public getAndSetProfile(): void {
    const profile = getProfileById(this.$route.params.profileId);
    this.setPortfolioInView(profile);
    if (this.customType && !this.allAllocationsDataEntered) {
      setSlideOutByKey('allocationSlideOutInView', true);
    }
  }

  public routeToProfile(id): void {
    this.$router.push(`/profile/${id}/climate-risk`);
    this.profileChanged = true;
    this.getAndSetProfile();
  }

  public setToCustomiseAllocation() {
    setPorfolioType('custom');
  }

  public gotToPathway(): void {
    this.$router.push(`/profile/${this.$route.params.profileId}/pathway`);
  }

  public setPortfolioInView(profile: any): void {
    setPortfolioInView(profile, true);
  }

  public get changedFromStandardTierMessage(): string {
    return getChangedFromStandard();
  }

  public get modelData(): any {
    return modelData;
  }

  public get activePortfolio(): any {
    return activePortfolio;
  }

  public get yearOneCarbon(): number {
    return activePortfolio.calculationResults.pathways.yearOneCarbon;
  }

  public get CarsOnRoad(): number {
    return this.yearOneCarbon / modelData.transitionalChanges.comparisonData.co2percar;
  }

  public get AnnualCost(): number {
    return this.yearOneCarbon * modelData.transitionalChanges.comparisonData.costperton;
  }

  public get OneTonneCost(): number {
    return modelData.transitionalChanges.comparisonData.costperton;
  }

  public get teirResults(): any {
    return activePortfolio.calculationResults.tier;
  }

  public get showCustom(): boolean {
    return (activePortfolio as any)?.name !== 'Your allocation' && (activePortfolio as any)?.type === 'custom';
  }
  public get disableCloseCheck(): boolean {
    let isDisabled = false;
    if (this.customTypeCheck) {
      isDisabled = true;
      return isDisabled;
    }
    return isDisabled;
  }
  public get profileList(): any[] {
    return this.allProfiles.filter((profile) => profile.id !== (activePortfolio as any).id);
  }

  public get currentAllocationTotalCheck(): boolean {
    return activePortfolio.currentAllocationTotal > 0;
  }
  public get allProfiles(): any {
    return profiles.defaultProfiles;
  }

  public get allAllocationsDataEntered(): boolean {
    return getters.allAllocationsDataEntered();
  }

  public get customType(): boolean {
    return (activePortfolio as any).name === 'Your allocation';
  }

  public get currentAllocationChart(): any[] {
    let all = [];
    modelData.universeData.forEach((assetClass) => {
      assetClass.subCategories.forEach((subClass) => {
        const allObj = activePortfolio.currentAllocation.findIndex((a) => a.id === subClass.id);
        if (allObj > -1) {
          const findAsset = all.findIndex((a) => a.id === assetClass.id);
          if (findAsset > -1) {
            all[findAsset].allocation += activePortfolio.currentAllocation[allObj].allocation;
          } else {
            all.push({
              id: assetClass.id,
              name: assetClass.name,
              allocation: activePortfolio.currentAllocation[allObj].allocation,
            });
          }
        }
      });
    });

    return all;
  }

  public get customTypeCheck(): boolean {
    const total = 100;
    return this.customType && activePortfolio.currentAllocationTotal !== total;
  }

  public get chartHeight(): number {
    if (this.$mq === 'md') return 500;
    return 450;
  }

  public get portfolioTierDataForChart(): any {
    const tierData = activePortfolio.calculationResults.tier.tierAllocations;
    const assetData = activePortfolio.calculationResults.tier.tierAssetAllocations;

    const chartOptions = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        height: this.chartHeight,
      },
      legend: {
        align: 'center',
        verticalAlign: 'bottom',
      },
      title: {
        text: null,
        floating: true,
      },
      tooltip: {
        headerFormat: '',
        pointFormat: '{point.name}: <b>{point.percentage:.1f}%</b>',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            formatter: function () {
              return this.y !== 0 ? `<b>${this.point.name}</b> ${Math.round(this.point.percentage * 10) / 10}%` : null;
            },
            // format: '<b>{point.name}</b>: {point.percentage:.1f} %'
          },
          showInLegend: true,
        },
        series: {
          groupPadding: 0,
          pointPadding: 0,
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: 'Asset tier',
          data: tierData,
          size: '80%',
          innerSize: '60%',
        },
        {
          name: 'Asset allocations',
          data: assetData,
          size: '40%',
          showInLegend: false,
          dataLabels: {
            enabled: false,
          },
        },
      ],
    };
    return chartOptions;
  }
}
