import Vue from "vue";
import { getPortfolioByName } from "./portfolios";

export const profiles = Vue.observable({
  defaultProfiles: [
    {
      id: "1ccdef23-f731-478b-aa5c-cf843b7b5061",
      name: "Growth",
      type: "standard",
      climateLadderList: ["Equity", "Private markets", "Multi assets"],
      scoring: {
        average: 2.7,
        potential: 3.6,
      },
      marketInvestorProportion: 25,
      allocations: [
        {
          id: "developedequitiesbenchmark",
          allocation: 47,
        },
        {
          id: "emergingmarketsbenchmark",
          allocation: 4,
        },
        {
          id: "dgfstandard",
          allocation: 9,
        },
        {
          id: "corporatebondsstandard",
          allocation: 8,
        },
        {
          id: "infrastructurestandard",
          allocation: 2,
        },
        {
          id: "property",
          allocation: 5,
        },
        {
          id: "multiassetcredit",
          allocation: 4,
        },
        {
          id: "privatedebtandequities",
          allocation: 2,
        },
        {
          id: "giltsldi",
          allocation: 19,
        },
      ],
    },

    {
      id: "5ca43465-618f-4148-b6f0-a2cf915277d6",
      type: "standard",
      name: "Diversifier",
      climateLadderList: ["Private markets", "Equity", "Multi assets"],
      scoring: {
        average: 2.3,
        potential: 2.9,
      },
      marketInvestorProportion: 20,
      allocations: [
        {
          id: "developedequitiesbenchmark",
          allocation: 16,
        },
        {
          id: "emergingmarketsbenchmark",
          allocation: 2,
        },
        {
          id: "dgfstandard",
          allocation: 27,
        },
        {
          id: "corporatebondsstandard",
          allocation: 13,
        },
        {
          id: "infrastructurestandard",
          allocation: 2,
        },
        {
          id: "property",
          allocation: 3,
        },
        {
          id: "multiassetcredit",
          allocation: 9,
        },
        {
          id: "privatedebtandequities",
          allocation: 3,
        },
        {
          id: "giltsldi",
          allocation: 25,
        },
      ],
    },

    {
      id: "fe877d21-9c3c-4212-96d9-90ea28b0a10a",
      name: "De-risker",
      type: "standard",
      climateLadderList: ["Private markets", "Equity",  "Corporate bonds"],
      scoring: {
        average: 2.8,
        potential: 3.5,
      },
      marketInvestorProportion: 42,
      allocations: [
        {
          id: "developedequitiesbenchmark",
          allocation: 14,
        },
        {
          id: "emergingmarketsbenchmark",
          allocation: 1,
        },
        {
          id: "dgfstandard",
          allocation: 8,
        },
        {
          id: "corporatebondsstandard",
          allocation:24,
        },
        {
          id: "infrastructurestandard",
          allocation: 2,
        },
        {
          id: "property",
          allocation: 3,
        },
        {
          id: "multiassetcredit",
          allocation: 6,
        },
        {
          id: "privatedebtandequities",
          allocation: 3,
        },
        {
          id: "giltsldi",
          allocation: 39,
        },
      ],
    },
    {
      id: "bb3e3565-92b7-4938-8d05-471322c2a89a",
      name: "Matching",
      type: "standard",
      climateLadderList: ["Private markets", "Corporate bonds", "Multi assets"],
      scoring: {
        average: 3.3,
        potential: 3.8,
      },
      marketInvestorProportion: 13,
      allocations: [
        {
          id: "developedequitiesbenchmark",
          allocation: 2,
        },
        {
          id: "emergingmarketsbenchmark",
          allocation: 0,
        },
        {
          id: "dgfstandard",
          allocation: 4,
        },
        {
          id: "corporatebondsstandard",
          allocation: 24,
        },
        {
          id: "infrastructurestandard",
          allocation: 0,
        },
        {
          id: "property",
          allocation: 1,
        },
        {
          id: "multiassetcredit",
          allocation: 0,
        },
        {
          id: "privatedebtandequities",
          allocation: 1,
        },
        {
          id: "giltsldi",
          allocation: 68,
        },
      ],
    },
    {
      id: "d9f1ac0d-06c3-4ed7-8dd6-f981e758abe9",
      name: "Your allocation",
      type: "custom",
      chartData: [
        {
          name: "Custom",
          data: [
            {
              name: "?",
              y: 40,
              color: "#d2d4dc",
            },
            {
              name: "?",
              y: 20,
              color: "#e5e6eb",
            },
            {
              name: "?",
              y: 20,
              color: "#d2d3db",
            },
            {
              name: "?",
              y: 5,
              color: "#e5e6eb",
            },
            {
              name: "?",
              y: 15,
              color: "#c0c2ce",
            },
          ],
        },
      ],
      climateLadderList: ["?", "?", "?"],
      scoring: {
        average: 0,
        potential: 0,
      },
      allocations: [],
    },
  ],
});

export const getProfileById = (profileId: string): any => {
  return profiles.defaultProfiles.find((profile) => profile.id === profileId);
};
