

























































// eslint-disable-next-line no-irregular-whitespace
import { Component, Vue } from "vue-property-decorator";
import Terms from "@/components/terms/Terms.vue";
import Parallelograms from "@/components/UI/Parallelograms.vue";
import Footer from "@/components/Footer.vue";
import { showTerms } from "@/observables/ui";

@Component({
  components: {
    Terms,
    Footer,
    Parallelograms,
  },
})
export default class HomeIntroTerms extends Vue {
  public exploreTool(): void {
    this.$router.push("/profile");
  }

  public showTermsAndConditions(): void {
    showTerms();
  }
}
