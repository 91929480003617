









































interface ProfileOption {
  id: string;
  name: string;
  chartData: any;
  climateLadderList: string[];
  scoring: {
    average: number;
    potential: number;
  };
}
import { Component, Vue, Prop } from "vue-property-decorator";
import {
  getUniverseParentDataById,
} from "@/observables/portfolios";

@Component
export default class ProfileSelector extends Vue {
  @Prop() profile!: any;
  @Prop(Boolean) customProfile!: boolean;

  public chartOptions = {};

  mounted(): void {
    let seriesData = [];

    this.profile.allocations.forEach(asset => {
      const assetData = getUniverseParentDataById(asset.id);
      seriesData.push({
        name: assetData.name,
        y: asset.allocation,
        color: assetData.color,
      });
    });

    let series = {
      name: this.profile.name,
      data: seriesData,
    };

    this.chartOptions = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
        height: 200,
        margin: [0, 0, 0, 0],
      },
      title: {
        text: null,
        floating: true,
      },
      tooltip: {
        pointFormat: "{series.name}",
        formatter: function () {
          return this.point.name + "</b>: <b>" + this.y + "%</b>";
        },
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: false,
          },
        },
        series: {
          groupPadding: 0,
          pointPadding: 0,
        },
      },
      credits: {
        enabled: false,
      },
    };
    (this.chartOptions as any).series = seriesData.length == 0? this.profile.chartData : series;
  }



  public routeToProfile(): void {
    this.$router.push(`/profile/${this.profile.id}/allocations`);
  }

  public routeToCustom(): void {
    this.$router.push(`/profile/${this.profile.id}`);
  }
}
