








import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component({ inheritAttrs: false })
export default class TextInput extends Vue {
  public localValue: string | number = '';
  @Prop() value!: string | number;

  public get listeners(): any {
    return {
      ...this.$listeners,
    };
  }

  @Watch('value', {immediate: true})
  updateLocalValue(value: string | number) {

      this.localValue = value;

  }



}
