import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import ProfileSelection from "@/views/ProfileSelection.vue";
import HomeIntroTerms from "@/views/HomeIntroTerms.vue";
import { closeAllocationSlideOut } from "@/observables/ui";
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "HomeIntroTerms",
    component: HomeIntroTerms,
    meta: {
      needData: false
    }
  },
  {
    path: "/profile",
    name: "Profile",
    component: ProfileSelection,
    meta: {
      needData: false
    }
  },
  {
    path: "/profile/:profileId/allocations",
    name: "Allocations",
    component: () => import("@/views/Allocations.vue"),
    meta: {
      needData: false
    }
  },

  {
    path: "/profile/:profileId/climate-risk",
    name: "ClimateRisk",
    component: () => import("@/views/ProfileDetails.vue"),
    meta: {
      needData: true
    }
  },
  {
    path: "/profile/:profileId/pathway",
    name: "Pathway",
    component: () => import("@/views/Pathway.vue"),
    meta: {
      needData: true
    }
  },
  {
    path: "/custom-profile",
    name: "CustomProfile",
    component: () => import("@/views/ProfileDetails.vue"),
    meta: {
      needData: true
    }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

import { activePortfolio} from '@/observables/portfolios';

router.beforeEach((to, from, next) => {
  if (activePortfolio.currentAllocation == null && to.meta.needData) {
    next('/');
  } else {
    next();
  }
});

export default router;
