













































import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class AllocationOverlay extends Vue {
  @Prop(Boolean) isCustomisable: boolean;

  public get pathName(): string {
    return this.$route.name;
  }
}
