

















import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { ui, setSlideOutByKey } from "@/observables/ui";

@Component
export default class SlideOut extends Vue {
  @Prop(Boolean) left!: boolean;
  @Prop(String) tabText!: string;
  @Prop(Boolean) disableClose!: boolean;
  @Prop(String) sliderKey: string;

  public isSlideOut = false;

  public toggleSlideOut(): void {
    if (this.disableClose && this.sliderKey === "allocationSlideOutInView")
      return;
    setSlideOutByKey(this.sliderKey, !this.isSlideOut);
  }

  public get ui(): any {
    return ui;
  }

  public get slideOutView(): boolean {
    return ui[this.sliderKey];
  }

  @Watch("slideOutView", { immediate: true })
  updateSlideOut(slideOutInView: boolean) {
    this.isSlideOut = slideOutInView;
  }
}
