

























import { Component, Vue, Prop } from "vue-property-decorator";
import { ui, showTier } from '@/observables/ui';

@Component
export default class InfoIcon extends Vue {
  @Prop() contentToShow!: string;
  @Prop() iconNumber!: number;
  @Prop({default: 'info-circle'}) icon: string;
  @Prop({default: true}) isModalEnabled!: boolean;
  @Prop({default: 'modalDisplay'}) type!: string;


  public currentIcon: null | number = null;

  public handleClick() {
    if(this.type === 'assetChange') {
      showTier();
      if (ui.showTierColumn && this.$route.name !== 'Allocations') {
      this.$router.push(`/profile/${this.$route.params.profileId}/allocations`);
    }
      return
    }
    if(!this.isModalEnabled) return;
    this.$store.commit("updateModalContent", this.contentToShow);
  }

  get hoverContent (): string {
    return this.isModalEnabled ? 'click for more' : this.contentToShow;
  }
}
