














import { Component, Vue } from "vue-property-decorator";
import Terms from "@/components/terms/Terms.vue";
import { closeTerms } from "@/observables/ui";

@Component({
  components: {
    Terms,
  },
})
export default class TermsWrapper extends Vue {
  public closeTermsAndConditions(): void {
    closeTerms();
  }
}
