












import { Component, Vue } from "vue-property-decorator";
import { showTerms } from "@/observables/ui";

@Component
export default class Footer extends Vue {
  public showTermsAndConditions(): void {
    showTerms();
  }
}
