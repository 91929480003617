

























import { Component, Vue } from "vue-property-decorator";
import ProfileSelector from "@/components/ProfileSelector.vue";
import ProfileAssetLegend from "@/components/ProfileAssetLegend.vue";
import { profiles } from "@/observables/profiles";

@Component({
  components: { ProfileSelector, ProfileAssetLegend },
})
export default class ProfileSelection extends Vue {
  public profileDefaults = profiles.defaultProfiles;
}
