
















import { Component, Vue } from "vue-property-decorator";
import { modelData } from "@/observables/portfolios";

@Component
export default class ProfileAssetLegend extends Vue {
  get Assets() {
    return modelData.universeData;
  }
}
