














import { Component, Vue } from "vue-property-decorator";
@Component
export default class MobileOverlay extends Vue {
  data() {
    return {};
  }
}
