import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Popover from "vue-js-popover";
import Highcharts from "highcharts";
import HighchartsVue from "highcharts-vue";
import LoadingComponent from "./components/UI/LoadingComponent.vue";
import VueMq from "vue-mq";
import VTooltip from "v-tooltip";

Vue.use(HighchartsVue);
Vue.use(VTooltip, {
  defaultTrigger: window.innerWidth > 768 ? 'hover focus click' : 'click'
})

import "bootstrap/dist/css/bootstrap-grid.min.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChartArea,
  faChartLine,
  faWind,
  faRoute,
  faHomeLgAlt,
  faCopy,
  faTimes,
  faChevronDown,
  faChevronLeft,
  faLongArrowRight,
  faInfoCircle,
  faNetworkWired,
  faCommentAltLines,
  faCog,
  faSync
} from "@fortawesome/pro-light-svg-icons";
// import { library } from "@fortawesome/pro-duotone-svg-icons";
// import { faCaretDown, faTimes } from "@fortawesome/pro-solid-svg-icons";
// import { faCreativeCommonsZero } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faChartArea,
  faChartLine,
  faWind,
  faRoute,
  faHomeLgAlt,
  faCopy,
  faTimes,
  faChevronDown,
  faChevronLeft,
  faLongArrowRight,
  faInfoCircle,
  faNetworkWired,
  faCommentAltLines,
  faCog,
  faSync
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("LoadingComponent", LoadingComponent);
Vue.use(Popover, { tooltip: true });
Vue.use(VueMq, {
  breakpoints: {
    sm: 767.98,
    md: 768,
    mdLandscape: 1024,
    lg: Infinity
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
