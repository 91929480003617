export const tierInfo = {
  content: `
    <div class="info-heading">
    <h2>What the tiers mean</h2>
    </div>
    <p>LCP has developed a five-tier scale to provide a straightforward way to triage
    an institutional investor’s exposure to climate related risks in their portfolio using
    the high-level asset allocation.
    </p>
    <p>We determine these tiers based on three criteria:</p>
    <ul>
        <li>The availability, or otherwise, of data to assess climate risks (allocating asset
            classes to the lowest tier if data is unavailable).
        </li>
        <li>
        The overall carbon intensity of the asset class as determined by commonly
        used benchmark indices.
        </li>
        <li>
        The presence of climate opportunities within the asset class on average, such
        as due to innovation prompted by a response to climate risks (allocating to the
        highest tier for the portion representing climate opportunities).
        </li>
    </ul>
    <p>This gives a very quick and easy way of highlighting which parts of the portfolio to
    undertake further detailed, security level analysis to determine risks more precisely,
    or where the efforts should be put in terms of obtaining more data from managers.
    This does not give a definitive view of climate risk, but it does help institutional
    investors to prioritise their key action.
    </p>
    `,
};
export const pathwaysInfo = {
  pathway: `
      <div class="info-heading">
        <h2>Pathways chart</h2>
      </div>
    <p>These pathways are for illustrative purposes only. They are based on asset-class level assumptions starting from emissions data on index tracking ETFs (source: <a target="_blank" href="https://fossilfreefunds.org/">fossil free funds.org</a>) ) and projected forward using broad market potential emissions trajectories sourced from scenarios in the IPCC report. For the central projection we have taken IPCC’s P3 scenario which is described as: “ a middle of the road scenario in which societal as well as technological development follows historical patterns. Emissions reductions are mainly achieved by changing the way in which energy and products are produced, and to a lesser degree by reductions in demand.</p>
    <p>For private markets and other assets we are making assumptions based on the closest available asset class comparator for which data is available.</p>
    <p>Specific portfolio emissions and trajectories may vary substantially from these due to sector differences. For your portfolio it is recommended to undertake a sector-level emissions trajectory analysis using science backed pathways applied to your specific sector composition.</p>`,
  alignment: `
      <div class="info-heading">
        <h2>Alignment chart</h2>
      </div>
    <p>Alignment is a forward looking assessment of a company's business plan relative to a science-backed, sector level pathway consistent with the Paris agreement.</p> <p>There are a number of independent organisations that publish assessments such as Climate Action 100+, The Transition Pathway Initiative and the Science Based Targets Initiative.</p> <p>There is currently limited data on the annual pace of alignment but the SBTi's progress report contains useful information on the number of companies Aligned and Committed (in the process of  aligning).</p>
    <p><a href="https://sciencebasedtargets.org/sbti-progress-rport-2020" target="_blank">https://sciencebasedtargets.org/sbti-progress-rport-2020</a></p>
    <p><a href="https://www.transitionpathwayinitiative.org/" target="_blank">https://www.transitionpathwayinitiative.org</a></p>
    <p><a href="https://www.climateaction100.org/" target="_blank">https://www.climateaction100.org</a></p>`,

  cars: `<div class="pathway-card-info">
              <div class="info-heading">
              <h2>Cars equivalent information</h2>
              </div>
              <p>When calculating this value, the total portfolio CO2 emission is based on the estimated scope 1 &amp; 2 emissions of your portfolio, excluding gilts.  See this <a href="https://www.carbontrust.com/resources/briefing-what-are-scope-3-emissions" target="_blank">link</a> for more information.
              </p>
              <p>To estimate the CO2 emissions for a single car we have used the following UK best selling model in 2021: <span class="highlights">Vauxhall Corsa</span>.</p>
              <p>Petrol version produces <span class="highlights">125g / km</span>.</p>
              <p>Based on average mileage for a new car is <br><span class="highlights">10,300 miles per year</span> = 16,500 kms 125 x 16,500 = 2.1 tonnes per year per car.</p>
            </div>`,
  cost:  `<div class="costinfo">
            <div class="info-heading">
            <h2>Cost of carbon</h2>

            <p>You can view the current ETS price of carbon at <a href="https://ember-climate.org/data/carbon-price-viewer/"  target="_blank">https://ember-climate.org/data/carbon-price-viewer/</a>.  We are using a price of £71 based on the cost in January 2022.</p>
            </div>`,
};
