











import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class InfoIconToolTip extends Vue {
  @Prop() content!: string;
  @Prop() iconNumber!: number;
  @Prop({default: false}) isEvolveAllocation!: boolean

  public currentIcon: null | number = null;
}
