













import { Component, Vue, Prop } from "vue-property-decorator";
import InfoIcon from "@/components/UI/InfoIcon.vue";
@Component({
  components: {
    InfoIcon,
  },
})
export default class CustomCard extends Vue {
  @Prop() cardContent!: any;
  @Prop() cardIndex!: number;

  public currentIndex: number | null = null;
  public currentHoverIndex: number | null = null;

  public showMoreInfoModal(): void {
    this.currentIndex = this.cardIndex;
  }

  public closeModal(): void {
    this.currentIndex = null;
  }
}
