import Vue from 'vue';

export const modelData = Vue.observable({
  transitionalChanges: {
    years: [2025, 2030, 2035, 2040, 2050],
    categories: {
      'IPCC Upper quartile': {
        data: [-0.29, -0.58, -0.7, -0.83, -1.07],
        color: '#D4DC5C',
      },
      'IPCC Scenario P3 - central': {
        data: [-0.21, -0.41, -0.54, -0.66, -0.91],
        color: '#F7A600',
      },
      'IPCC Lower quartile': {
        data: [-0.07, -0.14, -0.34, -0.54, -0.94],
        color: '#E93F6F',
      },
    },
    comparisonData: {
      co2percar: 2.1,
      costperton: 71,
    },
  },
  alignmentData: {
    'Not aligned': {
      color: '#B71543',
    },
    'No data': {
      color: '#E93F6F',
    },
    Aligning: {
      color: '#F7A600',
    },
    Aligned: {
      color: '#D4DC5C',
    },
  },
  universeData: [
    {
      id: 'developedEquities',
      name: 'Developed equities',
      color: '#59599a',
      showInLegend: true,
      subCategories: [
        {
          id: 'developedequitiesbenchmark',
          class: 'Developed equities',
          name: 'Benchmark',
          tier: 3,
          lcpDefaultTier: 3,
          footprint: 98,
          aligning: 0.15,
          aligned: 0.15,
          alignmentRate: 'slow',
          lowCarbonMapping: [
            {
              id: 'developedequitieslowcarbon',
              proportion: 0.66,
            },
            {
              id: 'developedequitiesclimateopportunities',
              proportion: 0.34,
            },
          ]
        },
        {
          id: 'developedequitieslowcarbon',
          class: 'Developed equities',
          name: 'Climate aware',
          tier: 4,
          lcpDefaultTier: 4,
          footprint: 28,
          aligning: 0.2,
          aligned: 0.2,
          alignmentRate: 'fast',
        },
        {
          id: 'developedequitiesclimateopportunities',
          class: 'Developed equities',
          name: 'Climate opportunities',
          tier: 5,
          lcpDefaultTier: 5,
          footprint: 28,
          aligning: 0.2,
          aligned: 0.2,
          alignmentRate: 'fast',
        },
      ],
    },
    {
      id: 'emergingMarkets',
      name: 'Emerging market equities (or debt)',
      color: '#dc3912',
      showInLegend: true,
      subCategories: [
        {
          id: 'emergingmarketsbenchmark',
          class: 'Emerging market equities (or debt)',
          name: 'Benchmark',
          tier: 2,
          lcpDefaultTier: 2,
          footprint: 234,
          aligning: 0.08,
          aligned: 0.08,
          alignmentRate: 'slow',
          lowCarbonMapping: [
            {
              id: 'emergingmarketslowcarbon',
              proportion: 1,
            },
          ]
        },
        {
          id: 'emergingmarketslowcarbon',
          class: 'Emerging market equities (or debt)',
          name: 'Climate aware',
          tier: 4,
          lcpDefaultTier: 4,
          footprint: 33,
          aligning: 0.2,
          aligned: 0.15,
          alignmentRate: 'fast',
        },
      ],
    },
    {
      id: 'dgf',
      name: 'Diversified growth',
      color: '#e8c0dc',
      showInLegend: true,
      subCategories: [
        {
          id: 'dgfstandard',
          class: 'Diversified growth',
          name: 'Standard',
          tier: 1,
          lcpDefaultTier: 1,
          footprint: 85,
          aligning: 0.1,
          aligned: 0.1,
          alignmentRate: 'slow',
        },
      ],
    },
    {
      id: 'igCorporateBonds',
      name: 'Corporate bonds',
      color: '#015357',
      showInLegend: true,
      subCategories: [
        {
          id: 'corporatebondsstandard',
          class: 'Corporate bonds',
          name: 'Standard',
          tier: 2,
          lcpDefaultTier: 2,
          footprint: 157,
          aligning: 0.12,
          aligned: 0.12,
          alignmentRate: 'slow',
          lowCarbonMapping: [
            {
              id: 'corporatebondslowcarbon',
              proportion: 1,
            },
          ]
        },
        {
          id: 'corporatebondslowcarbon',
          class: 'Corporate bonds',
          name: 'Climate aware',
          tier: 4,
          lcpDefaultTier: 4,
          footprint: 35,
          aligning: 0.2,
          aligned: 0.15,
          alignmentRate: 'fast',
        },
      ],
    },
    {
      id: 'infrastructure',
      name: 'Infrastructure',
      color: '#990099',
      showInLegend: true,
      subCategories: [
        {
          id: 'infrastructurestandard',
          class: 'Infrastructure',
          name: 'Standard',
          tier: 2,
          lcpDefaultTier: 2,
          footprint: 478,
          aligning: 0.15,
          aligned: 0.15,
          alignmentRate: 'slow',
          lowCarbonMapping: [
            {
              id: 'infrastructureclimateopportunities',
              proportion: 1,
            },
          ]
        },
        {
          id: 'infrastructureclimateopportunities',
          class: 'Infrastructure',
          name: 'Climate opportunities',
          tier: 5,
          lcpDefaultTier: 5,
          footprint: 200,
          aligning: 0.2,
          aligned: 0.15,
          alignmentRate: 'fast',
        },
      ],
    },
    {
      id: 'property',
      name: 'Property',
      color: '#0099c6',
      showInLegend: true,
      subCategories: [
        {
          id: 'property',
          class: 'Property',
          name: '',
          tier: 1,
          lcpDefaultTier: 1,
          footprint: 15,
          aligning: 0.1,
          aligned: 0.1,
          alignmentRate: 'slow',
        },
      ],
    },
    {
      id: 'multiAssetCredit',
      name: 'Multi-asset credit',
      color: '#dd4477',
      showInLegend: true,
      subCategories: [
        {
          id: 'multiassetcredit',
          class: 'Multi-asset credit',
          name: '',
          tier: 2,
          lcpDefaultTier: 2,
          footprint: 242,
          aligned: null,
        },
      ],
    },
    {
      id: 'privatedebtandequities',
      name: 'Private debt and equities',
      color: '#008f8f',
      showInLegend: true,
      subCategories: [
        {
          id: 'privatedebtandequities',
          class: 'Private debt and equities',
          name: '',
          tier: 1,
          lcpDefaultTier: 1,
          footprint: 242,
          aligned: null,
        },
      ],
    },
    {
      id: 'giltsLdi',
      name: 'Gilts, Cash, LDI & Annuities',
      color: '#821a4d',
      showInLegend: true,
      subCategories: [
        {
          id: 'giltsldi',
          class: 'Gilts, Cash, LDI & Annuities',
          name: '',
          tier: 4,
          lcpDefaultTier: 4,
          footprint: 0,
          aligning: 0,
          aligned: 1,
          alignmentRate: 'slow',
        },
      ],
    },
  ],
  tierData: [
    {
      categoryLevel: 1,
      categoryDescription: 'No data or data not good enough today',
      color: '#fc5f5f',
    },
    {
      categoryLevel: 2,
      categoryDescription: 'Climate risks high',
      color: '#e37100',
    },
    {
      categoryLevel: 3,
      categoryDescription: 'Climate risks likely',
      color: '#ff9900',
    },
    {
      categoryLevel: 4,
      categoryDescription: 'Climate risks moderate',
      color: '#7be77b',
    },
    {
      categoryLevel: 5,
      categoryDescription: 'Climate investment opportunities',
      color: '#4aad4a',
    },
  ],
  footprintYear: 2021,
  footprintBaseValue: 1000000,
  maxYearPathways: 2040,
  averagePensionFundScore: 2.7,
  alignmentRates: {
    fast: 0.06,
    slow: 0.03,
  },
});

export const updateAlignmentRate = (id: string, val: number): void => {
  modelData.alignmentRates[id] = val;
  calculateResults();
};

export const getUniverseDataById = (id: string): any => {
  return modelData.universeData
    .map((asset) => asset.subCategories)
    .flat()
    .find((s) => s.id === id);
};

export const getUniverseParentDataById = (id: string): any => {
  return modelData.universeData.find((a) => a.subCategories.find((s) => s.id === id)?.id === id);
};

export const getPortfolioByKey = (id: string): any => {
  return modelData.universeData.find((asset) => asset.id === id);
};

export const getPortfolioByName = (name: string): any => {
  return modelData.universeData.find((asset) => asset.name === name);
};

export const activePortfolio = Vue.observable({
  name: '',
  type: '',
  id: '',
  currentAllocation: null,
  futureAllocation: null,
  currentAllocationTotal: 0,
  futureAllocationTotal: 0,
  marketValue: 0,
  futureTargetYear: '',
  updateEvolve: false,
  currency: '£',
  portfolioView: 'All excluding gilts',
  pathwayView: 'normal',
  calculationResults: {
    projectionYears: [],
    chartAssetAllocationCurrent: [],
    chartAssetAllocationFuture: [],
    allocationProgression: [],
    excludeAllocation: [],
    tier: {
      climateLadder: {
        suggestions: [],
        risks: [],
      },
      currentScore: 0,
      potentialScore: 0,
      tierAllocations: [],
      tierAssetAllocations: [],
    },
    pathways: {
      yearOneCarbon: 0,
      yearOneCarbonDecarbon: 0,
      carbonScenarios: {},
      carbonScenariosDecarbon: {},
      alignmentScenarios: {},
      target2030: [],
    },
  },
});

export const getters: any = {
  allAllocationsDataEntered: () =>
    activePortfolio.currentAllocationTotal === 100 &&
    ((activePortfolio.futureAllocationTotal === 100 && activePortfolio.updateEvolve) || !activePortfolio.updateEvolve) &&
    activePortfolio.marketValue !== 0 &&
    ((activePortfolio.futureTargetYear !== '' && activePortfolio.updateEvolve) || !activePortfolio.updateEvolve),
};

export const setEvovleStrategy = (value: string) => {
  if (value === 'Yes') {
    activePortfolio.updateEvolve = true;
  } else {
    activePortfolio.updateEvolve = false;
  }
  calculateResults();
};

export const setPortfolioView = (value: string) => {
  activePortfolio.portfolioView = value;
  calculateResults();
};

export const setPathwayView = (value: string) => {
  activePortfolio.pathwayView = value;
  calculateResults();
};
export const setCurrency = (currency: string) => {
  activePortfolio.currency = currency;
};

const setTotalAllocations = (): void => {
  Vue.set(
    activePortfolio,
    'currentAllocationTotal',
    activePortfolio.currentAllocation.reduce((acc, value: any) => {
      return acc + value.allocation;
    }, 0)
  );

  Vue.set(
    activePortfolio,
    'futureAllocationTotal',
    activePortfolio.futureAllocation?.reduce((acc, value: any) => {
      return acc + value.allocation;
    }, 0)
  );
};

export const setMarketValue = (value: number) => {
  activePortfolio.marketValue = value;
  calculateResults();
};
export const setFutureTargetYear = (value: string) => {
  activePortfolio.futureTargetYear = value;
  calculateResults();
};

export const setPorfolioType = (type: string) => {
  activePortfolio.type = type;
};

export const setPortfolioInView = (portfolio: any, initialSetup = false): void => {
  if (portfolio.name != activePortfolio.name) {
    activePortfolio.name = portfolio.name;
    activePortfolio.type = portfolio.type;
    activePortfolio.id = portfolio.id;
    activePortfolio.marketValue = 0;
    activePortfolio.currentAllocation = portfolio.allocations;
    activePortfolio.futureAllocation = [];
    setTotalAllocations();
    activePortfolio.futureTargetYear = '2026';
    calculateResults();
  }
};

export const getAssetAllocationByAssetClass = (id: string): any => {
  let allocation = 0;
  const assetClass = modelData.universeData.find((asset) => asset.id === id);
  assetClass.subCategories.forEach((sub) => {
    const subClass = getAssetAllocationById(sub.id, 'currentAllocation');
    allocation += subClass ? subClass.allocation : 0;
  });
  return allocation;
};

export const getChangedFromStandard = (): string=> {
  let changed= false;
  let displayMessage = "";
  let assetClassName = "";
  let subName = "";

  modelData.universeData.forEach((assetClass) => {

    assetClass.subCategories.forEach((sub) => {
      if(sub.tier !== sub.lcpDefaultTier) {

        if(sub.name) {
          subName = `${sub.name}, from: ${sub.lcpDefaultTier}, to: ${sub.tier}`;
        } else {
          subName = `from: ${sub.lcpDefaultTier}, to: ${sub.tier}`;
        }
        assetClassName += `${assetClass.name}: (${subName}) `
        changed = true;
        displayMessage = `You've changed the following assets, ${assetClassName}`
      }
    });
  });

    return displayMessage;
  // return changed
}
export const resetTier = (): void => {
  modelData.universeData.forEach((assetClass) => {
    assetClass.subCategories.forEach((sub) => {
      if(sub.tier !== sub.lcpDefaultTier) {
        Vue.set(sub, 'tier', sub.lcpDefaultTier)
      }
     })
  });
  calculateResults();
}

export const getAssetAllocationById = (id: string, data: string): any => {
  return activePortfolio[data].find((allocation) => allocation.id === id);
};

export const updateAllocation = (allocation: any[], term: string): void => {
  Vue.set(activePortfolio, term, allocation);

  setTotalAllocations();
  calculateResults();
};
export const updateTiers = (id, value): void => {
  const parentObject = getUniverseParentDataById(id);

  const parentIndex = modelData.universeData.findIndex((parent: any) => parent.id === parentObject.id);
  const subCategoryIndex = modelData.universeData[parentIndex].subCategories.findIndex((subCategory: any) => subCategory.id === id);
  Vue.set(modelData.universeData[parentIndex].subCategories[subCategoryIndex], 'tier', parseInt(value));

  calculateResults();
};

const getAllocationArray = (assetId: string): any => {
  const prop = 1;

  return activePortfolio.calculationResults.allocationProgression.find((asset) => asset.id === assetId);
};

// <option>Equity</option>
// <option>Corporate bonds</option>
// <option>Total excl LDI</option>
// <option>All assets</option>

const getAssetToExclude = (analysisType: string): any => {
  switch (analysisType) {
    case 'All excluding gilts':
      return ['giltsldi'];
    case 'Equity':
      return ['giltsldi', 'privatedebtandequities', 'multiassetcredit', 'property', 'infrastructureclimateopportunities', 'infrastructurestandard', 'corporatebondslowcarbon', 'corporatebondsstandard', 'dgfstandard'];
    case 'Corporate bonds':
      return [
        'giltsldi',
        'privatedebtandequities',
        'multiassetcredit',
        'property',
        'infrastructureclimateopportunities',
        'infrastructurestandard',
        'emergingmarketslowcarbon',
        'emergingmarketsbenchmark',
        'dgfstandard',
        'developedequitiesclimateopportunities',
        'developedequitieslowcarbon',
        'developedequitiesbenchmark',
      ];
    default:
      return [];
  }
};

//-------------------------------------------------------------------------------------------------------------------------------------
//calculations

import moment, { max, Moment } from 'moment';
import { faIgloo, faLoveseat } from '@fortawesome/pro-light-svg-icons';

const calculateResults = (): void => {
  //---------------------------------------------------------------------------
  // Tier results

  const tierData = [0, 0, 0, 0, 0];
  const tierDataPotential = [0, 0, 0, 0, 0];
  activePortfolio.calculationResults.tier.tierAssetAllocations = [];

  activePortfolio.currentAllocation?.forEach((asset) => {
    const data = { ...getUniverseDataById(asset.id) };
    data.color = modelData.tierData[data.tier - 1].color;
    tierData[data.tier - 1] += asset.allocation;
    if (data.lowCarbonMapping !== undefined) {
      data.lowCarbonMapping.forEach((assetMapping) => {
        const dataLowC = getUniverseDataById(assetMapping.id);
        tierDataPotential[dataLowC.tier - 1] += asset.allocation * assetMapping.proportion;
      });
    } else {
      tierDataPotential[data.tier - 1] += asset.allocation;
    }
    //chart data
    activePortfolio.calculationResults.tier.tierAssetAllocations.push({
      name: data.name !== '' ? `${data.class} - ${data.name}` : `${data.class}`,
      tier: data.tier,
      y: asset.allocation,
      color: data.color,
    });
  });

  activePortfolio.calculationResults.tier.tierAssetAllocations = activePortfolio.calculationResults.tier.tierAssetAllocations.sort((a, b) => {
    return a.tier - b.tier;
  });

  activePortfolio.calculationResults.tier.tierAllocations = [];

  activePortfolio.calculationResults.tier.currentScore = 0;
  activePortfolio.calculationResults.tier.potentialScore = 0;

  modelData.tierData.forEach((data, index) => {
    activePortfolio.calculationResults.tier.tierAllocations.push({
      name: `Tier ${data.categoryLevel} - ${data.categoryDescription}`,
      y: tierData[index],
      allocation: tierData[index],
      color: data.color,
    });
    activePortfolio.calculationResults.tier.currentScore += (data.categoryLevel * tierData[index]) / 100;
    activePortfolio.calculationResults.tier.potentialScore += (data.categoryLevel * tierDataPotential[index]) / 100;
  });

  activePortfolio.calculationResults.tier.currentScore = Math.round(activePortfolio.calculationResults.tier.currentScore * 10) / 10;
  activePortfolio.calculationResults.tier.potentialScore = Math.round(activePortfolio.calculationResults.tier.potentialScore * 10) / 10;

  //get suggestions
  activePortfolio.calculationResults.tier.climateLadder.suggestions = [];
  activePortfolio.calculationResults.tier.climateLadder.risks = [];
  const eqAllocation = getAssetAllocationByAssetClass('developedEquities');
  const eqAllocationRisk = getAssetAllocationById('developedequitiesbenchmark', 'currentAllocation');
  const eqAllocationRiskVal = eqAllocationRisk? eqAllocationRisk.allocation : 0;
  const emeqAllocation = getAssetAllocationByAssetClass('emergingMarkets');
  const emeqAllocationRisk = getAssetAllocationById('emergingmarketsbenchmark', 'currentAllocation');
  const emeqAllocationRiskVal = emeqAllocationRisk? emeqAllocationRisk.allocation : 0;
  const privateAllocation = getAssetAllocationByAssetClass('privatedebtandequities');
  const corpAllocation = getAssetAllocationByAssetClass('igCorporateBonds');
  const infAllocation = getAssetAllocationByAssetClass('infrastructure');
  const giltsAllocation = getAssetAllocationByAssetClass('giltsLdi');
  const propAllocation = getAssetAllocationByAssetClass('property');
  const multiCreditAllocation = getAssetAllocationByAssetClass('multiAssetCredit');
  const dgfAllocation = getAssetAllocationByAssetClass('dgf');

  if (eqAllocationRiskVal+ emeqAllocationRiskVal> 25) {
    activePortfolio.calculationResults.tier.climateLadder.risks.push('Transition and physical risk in your equity portfolio');
  }

  if (corpAllocation > 20) {
    activePortfolio.calculationResults.tier.climateLadder.risks.push('Transition and physical risk in your corporate bond portfolio');
  }

  if (infAllocation > 0) {
    activePortfolio.calculationResults.tier.climateLadder.risks.push('Transition and physical risk in your infrastructure portfolio');
  }

  if (privateAllocation + propAllocation > 5) {
    activePortfolio.calculationResults.tier.climateLadder.risks.push('Lack of data in your private markets portfolios');
  }

  if (dgfAllocation > 5) {
    activePortfolio.calculationResults.tier.climateLadder.risks.push('Lack of data in your multi-asset portfolio');
  }

  if (propAllocation > 5) {
    activePortfolio.calculationResults.tier.climateLadder.risks.push('Transition and physical risk in your property portfolio');
  }

  if (eqAllocation + emeqAllocation > 30) {
    activePortfolio.calculationResults.tier.climateLadder.suggestions.push('Your equities: detailed fund-level climate analysis');
  }

  if (privateAllocation + propAllocation > 0) {
    activePortfolio.calculationResults.tier.climateLadder.suggestions.push('Your private market assets - request more data, interrogate managers on approach to climate risk analysis');
  }

  if (eqAllocation + emeqAllocation > 10) {
    activePortfolio.calculationResults.tier.climateLadder.suggestions.push('Move your equities to a Net-Zero targeting fund');
  }

  if (corpAllocation + multiCreditAllocation > 20) {
    activePortfolio.calculationResults.tier.climateLadder.suggestions.push('Move your corporate bonds to a climate aware fund');
  }

  //growth assets
  // if (corpAllocation + multiCreditAllocation > 0.2 ) {
  //   activePortfolio.calculationResults.tier.climateLadder.suggestions.push("Move your corporate bonds to a Net-Zero targeting fund");
  // }

  if (corpAllocation > 20) {
    activePortfolio.calculationResults.tier.climateLadder.suggestions.push('Your corporate bonds: detailed fund and sector-level analysis of climate risk');
    activePortfolio.calculationResults.tier.climateLadder.suggestions.push('Your corporate bonds: ask your manager their approach to engaging with issuers in key carbon-intense sectors such as utilities (eg do they insist on having science based targets in place?)');
  }

  if (infAllocation > 0) {
    activePortfolio.calculationResults.tier.climateLadder.suggestions.push('Request more data from infrastructure manager on data and risks, including scope 3 and physical risks, and Net Zero plans');
  }

  if (dgfAllocation > 0) {
    activePortfolio.calculationResults.tier.climateLadder.suggestions.push('Request more data from multi-asset manager(s) on data and risks - ask managers how they think about climate risk across asset classes');
  }

  if (propAllocation > 0) {
    activePortfolio.calculationResults.tier.climateLadder.suggestions.push("Ask your property manager about data on tenant & scope 3 emissions, and the managers's Net Zero pathway - are new assets assessed for Net Zero alignment? Analyse your sector breakdown eg industrial vs commercial");
  }

  if (giltsAllocation > 50) {
    activePortfolio.calculationResults.tier.climateLadder.suggestions.push('Ask your LDI manager about their approach to monitoring and engaging the UK government on emissions targets');
  }

  //---------------------------------------------------------------------------
  // Pathway results
  // portfolio progression
  if (getters.allAllocationsDataEntered()) {
    const porfolioProgerssion = [];

    const startYear = new Date().getFullYear();
    // porfolioProgerssion.years.push(startYear.toString());
    activePortfolio.calculationResults.projectionYears = [];
    for (let i = 0; i < 40; i++) {
      if (startYear + i <= modelData.maxYearPathways) activePortfolio.calculationResults.projectionYears.push((startYear + i).toString());
    }

    const universe = modelData.universeData.map((asset) => asset.subCategories).flat();

    const transitionTerm = parseInt(activePortfolio.futureTargetYear) - startYear;

    const excludeAllocation = new Array(activePortfolio.calculationResults.projectionYears.length + 1).fill(0);

    const assetsExcluded = getAssetToExclude(activePortfolio.portfolioView);

    universe.forEach((asset) => {
      let currentAllocation = getAssetAllocationById(asset.id, 'currentAllocation');
      let futureAllocation = getAssetAllocationById(asset.id, 'futureAllocation');

      if (currentAllocation == null && futureAllocation == null) return;
      if (currentAllocation == null) currentAllocation = { allocation: 0 };
      if (futureAllocation == null) futureAllocation = { allocation: 0 };

      const transitionRate = activePortfolio.updateEvolve ? (futureAllocation.allocation / 100 - currentAllocation.allocation / 100) / transitionTerm : 0;

      const allocations = new Array(activePortfolio.calculationResults.projectionYears.length + 1).fill(0);

      allocations[0] = currentAllocation.allocation / 100;

      if (assetsExcluded.includes(asset.id)) {
        excludeAllocation[0] += allocations[0];
      }

      for (let i = 1; i <= activePortfolio.calculationResults.projectionYears.length; i++) {
        if (i <= transitionTerm) {
          allocations[i] = currentAllocation.allocation / 100 + transitionRate * i;
        } else {
          allocations[i] = allocations[i - 1];
        }
        if (assetsExcluded.includes(asset.id)) {
          excludeAllocation[i] += allocations[i];
        }
      }

      porfolioProgerssion.push({
        id: asset.id,
        allocations: allocations,
      });
    });

    activePortfolio.calculationResults.allocationProgression = porfolioProgerssion;

    activePortfolio.calculationResults.excludeAllocation = excludeAllocation;

    // Calculate pathway carbon scenarios
    const carbonScenarios = {
      'IPCC Upper quartile': new Array(activePortfolio.calculationResults.projectionYears.length).fill(0),
      'IPCC Scenario P3 - central': new Array(activePortfolio.calculationResults.projectionYears.length).fill(0),
      'IPCC Lower quartile': new Array(activePortfolio.calculationResults.projectionYears.length).fill(0),
    };

    const carbonScenariosDecarbon = {
      'IPCC Upper quartile': new Array(activePortfolio.calculationResults.projectionYears.length).fill(0),
      'IPCC Scenario P3 - central': new Array(activePortfolio.calculationResults.projectionYears.length).fill(0),
      'IPCC Lower quartile': new Array(activePortfolio.calculationResults.projectionYears.length).fill(0),
    };

    const alignmentScenarios = {
      'Not aligned': new Array(activePortfolio.calculationResults.projectionYears.length).fill(0),
      'No data': new Array(activePortfolio.calculationResults.projectionYears.length).fill(0),
      Aligning: new Array(activePortfolio.calculationResults.projectionYears.length).fill(0),
      Aligned: new Array(activePortfolio.calculationResults.projectionYears.length).fill(0),
    };

    // pathway order
    // No data / Not aligned / Aligning / Aligned
    activePortfolio.calculationResults.pathways.yearOneCarbon = 0;
    activePortfolio.calculationResults.pathways.yearOneCarbonDecarbon = 0;

    universe.forEach((asset) => {
      const assetMix = getAllocationArray(asset.id);
      const assetNormal = getUniverseDataById(asset.id);
      let baseCarbonDecarbon = 0;
      if (assetNormal.lowCarbonMapping !== undefined) {
        assetNormal.lowCarbonMapping.forEach((assetMapping) => {
          const assetDecarbon = getUniverseDataById(assetMapping.id);
          baseCarbonDecarbon += assetDecarbon.footprint * assetMapping.proportion;
        });
      } else {
        baseCarbonDecarbon = assetNormal.footprint;
      }

      const baseCarbonNormal = assetNormal.footprint;
      let yearIndex = 0;
      if (assetMix) {
        const assetAllocation = assetMix.allocations;
        for (let t = 0; t < 40; t++) {
          const yr = modelData.footprintYear + t;
          const aligningFactors = getAligningFactors(yr, assetNormal);

          if (yr >= startYear && yr <= modelData.maxYearPathways) {
            for (const sc in carbonScenarios) {
              const footprintDecarbon = getFootprint(yr, sc, baseCarbonDecarbon);
              const footprintNormal = getFootprint(yr, sc, baseCarbonNormal);
              //footprint
              if (!assetsExcluded.includes(asset.id)) {
                carbonScenarios[sc][yearIndex] += (footprintNormal * assetAllocation[yearIndex] * activePortfolio.marketValue * 1000000) / modelData.footprintBaseValue;

                carbonScenariosDecarbon[sc][yearIndex] += (footprintDecarbon * assetAllocation[yearIndex] * activePortfolio.marketValue * 1000000) / modelData.footprintBaseValue;
              }

              if (yearIndex === 0 && sc === 'IPCC Scenario P3 - central' && !assetsExcluded.includes(asset.id)) {
                activePortfolio.calculationResults.pathways.yearOneCarbon += (baseCarbonNormal * assetAllocation[yearIndex] * activePortfolio.marketValue * 1000000) / modelData.footprintBaseValue;

                activePortfolio.calculationResults.pathways.yearOneCarbonDecarbon += (baseCarbonDecarbon * assetAllocation[yearIndex] * activePortfolio.marketValue * 1000000) / modelData.footprintBaseValue;
              }
            }

            //aligning
            if (!assetsExcluded.includes(asset.id)) {
              alignmentScenarios['Not aligned'][yearIndex] += (assetAllocation[yearIndex] / (1 - excludeAllocation[yearIndex])) * aligningFactors.notAligned;

              alignmentScenarios['No data'][yearIndex] += (assetAllocation[yearIndex] / (1 - excludeAllocation[yearIndex])) * aligningFactors.nodata;

              alignmentScenarios['Aligning'][yearIndex] += (assetAllocation[yearIndex] / (1 - excludeAllocation[yearIndex])) * aligningFactors.aligning;

              alignmentScenarios['Aligned'][yearIndex] += (assetAllocation[yearIndex] / (1 - excludeAllocation[yearIndex])) * aligningFactors.aligned;
            }

            yearIndex++;
          }
        }
      }
    });

    activePortfolio.calculationResults.pathways.carbonScenarios = carbonScenarios;
    activePortfolio.calculationResults.pathways.carbonScenariosDecarbon = carbonScenariosDecarbon;
    activePortfolio.calculationResults.pathways.alignmentScenarios = alignmentScenarios;
    activePortfolio.calculationResults.pathways.target2030 = new Array(activePortfolio.calculationResults.projectionYears.length).fill(0);
    activePortfolio.calculationResults.pathways.target2030[2030 - activePortfolio.calculationResults.projectionYears[0]] = activePortfolio.calculationResults.pathways.yearOneCarbon * Math.pow(0.93, 2030 - activePortfolio.calculationResults.projectionYears[0]);
  }

  // Vue.set(
  //   activePortfolio,
  //   "calculationResults",
  //   activePortfolio.calculationResults
  // );
};

const getFootprint = (year: number, sc: string, initialFootprint: number): number => {
  let i = 0;
  let ieach = 0;
  let yrOut = 0;

  if (modelData.transitionalChanges.years[0] <= year) {
    modelData.transitionalChanges.years.forEach((yr) => {
      if (yr > year && yrOut === 0) {
        i = ieach;
        yrOut = yr;
      }
      ieach++;
    });
  }

  if (i == 0) {
    const factorChange = modelData.transitionalChanges.categories[sc].data[i];
    const fullPeriod = modelData.transitionalChanges.years[i] - modelData.footprintYear;
    const partPeriod = year - modelData.footprintYear;

    const factor = (factorChange * partPeriod) / fullPeriod;

    return (1 + factor) * initialFootprint;
  } else {
    const factorChange = modelData.transitionalChanges.categories[sc].data[i] - modelData.transitionalChanges.categories[sc].data[i - 1];
    const fullPeriod = modelData.transitionalChanges.years[i] - modelData.transitionalChanges.years[i - 1];
    const partPeriod = year - modelData.transitionalChanges.years[i - 1];

    const factor = modelData.transitionalChanges.categories[sc].data[i - 1] + (factorChange * partPeriod) / fullPeriod;

    return (1 + factor) * initialFootprint;
  }
};

const getAligningFactors = (year: number, asset: any): any => {
  const nodata = 0;
  let notAligned = 0;
  let aligning = 0;
  let aligned = 0;

  if (asset.alignmentRate) {
    const rateOfAlignment = modelData.alignmentRates[asset.alignmentRate];
    const notAlignedBase = 1 - asset.aligned - asset.aligning;
    notAligned = Math.min(Math.max(notAlignedBase - rateOfAlignment * (year - modelData.footprintYear), 0), 1);
    aligning = Math.min(Math.max(asset.aligning - rateOfAlignment * (year - modelData.footprintYear) + (notAlignedBase - notAligned), 0), 1);
    aligned = 1 - notAligned - aligning;

    return {
      nodata: 0,
      notAligned: notAligned,
      aligning: aligning,
      aligned: aligned,
    };
  } else {
    //no data
    return {
      nodata: 1,
      notAligned: 0,
      aligning: 0,
      aligned: 0,
    };
  }

  return null;
};
