















import { Component, Vue } from "vue-property-decorator";
import HomeIntroTerms from "@/views/HomeIntroTerms.vue";
import TermsWrapper from "@/components/terms/TermsWrapper.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import BaseModal from "@/components/BaseModal.vue";
import MobileOverlay from "@/components/MobileOverlay.vue";
import { ui } from "@/observables/ui";
import vueNumeralFilterInstaller from "vue-numeral-filter";

Vue.use(vueNumeralFilterInstaller, { locale: "en-gb" });

@Component({
  components: {
    Header,
    Footer,
    BaseModal,
    HomeIntroTerms,
    TermsWrapper,
    MobileOverlay,
  },
})
export default class App extends Vue {
  public mediaQuerySize = null;
  public get isShowTerms(): boolean {
    return ui.showTerms;
  }

  public closeModal() {
    this.$store.commit("closeModal", null);
  }
  get modalContent() {
    return this.$store.state.modalContent;
  }

  // get mediaQuerySize() {
  //   return window.matchMedia("(max-width: 767.98px)");
  // }

  created() {
    this.mediaQuerySize = window.matchMedia("(max-width: 767.98px)");
  }
}
