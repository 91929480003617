












































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import {
  modelData,
  activePortfolio,
  setMarketValue,
  setFutureTargetYear,
  getters,
  updateAllocation,
} from "@/observables/portfolios";
import { ui } from "@/observables/ui";
import TextInput from "@/components/inputs/TextInput.vue";
import AllocationOverlay from "@/components/UI/AllocationOverlay.vue";

@Component({
  components: {
    TextInput,
    AllocationOverlay,
  },
})
export default class AllocationsTable extends Vue {
  @Prop(Boolean) isProfileChange!: boolean;
  @Prop() id!: string;
  isFutureEqCurrent = false;
  isCustomisable = false;

  public showAllocationWarning = false;

  public dates = [];
  public localPortfolio: any = [];

  created() {
    this.setShowAllocationWarning();
  }

  get portfolio() {
    return activePortfolio;
  }

  updateLocalPortfolio() {
    this.localPortfolio = [];
    const assetClasses = modelData.universeData
      .map((asset) => asset.subCategories)
      .flat();
    let assetC = "";
    assetClasses.forEach((c) => {
      const currentAllocation = this.portfolio.currentAllocation.find(
        (a) => a.id === c.id
      );
      const futureAllocation = this.portfolio.futureAllocation?.find(
        (a) => a.id === c.id
      );
      this.localPortfolio.push({
        id: c.id,
        assetClass: assetC == c.class ? "" : c.class,
        subClass: c.name,
        tier: c.tier,
        currentAllocation: currentAllocation ? currentAllocation.allocation : 0,
        futureAllocation: futureAllocation ? futureAllocation.allocation : 0,
      });
      assetC = c.class;
    });
  }

  get profileType(): string {
    return this.portfolio.type;
  }

  get assetClasss(): any {
    return modelData.universeData.map((asset) => asset.subCategories).flat();
  }

  public checkToCustomise() {
    this.showAllocationWarning = true;
    this.isCustomisable = true;
  }

  public setToCustomAllocation(): void {
    this.showAllocationWarning = false;
    this.$emit("setToCustomiseAllocation");
    this.isCustomisable = false;
    //
  }
  setShowAllocationWarning() {
    const { name } = this.$route;
    const isPathway =
      name === "Pathway" && this.portfolio.futureAllocationTotal < 1;
    if (this.portfolio.name === "Your allocation") {
      this.showAllocationWarning = true;
    }
    if (isPathway) {
      this.showAllocationWarning = true;
    }
  }

  public get yearsArray(): string[] {
    const years = [];
    let startYear = new Date().getFullYear();
    years.push(startYear.toString());
    for (let i = 0; i < 30; i++) {
      if (startYear < modelData.maxYearPathways)
        years.push((startYear += 1).toString());
    }
    return years;
  }

  public closeAllocationOverlay(): void {
    this.showAllocationWarning = false;
  }

  public setAllocation(value: string, id: string, property: string): void {
    const numberValue = parseInt(value);
    Vue.set(
      this.localPortfolio.find((a) => a.id === id),
      property,
      numberValue
    );

    updateAllocation(
      this.localPortfolio
        .filter((a) => a.currentAllocation > 0)
        .map((a) => ({
          id: a.id,
          allocation: a.currentAllocation,
        })),
      "currentAllocation"
    );

    updateAllocation(
      this.localPortfolio
        .filter((a) => a.futureAllocation > 0)
        .map((a) => ({
          id: a.id,
          allocation: a.futureAllocation,
        })),
      "futureAllocation"
    );
  }

  public copyCurrentAllocation() {
    this.localPortfolio.forEach((allocation, index) => {
      Vue.set(
        this.localPortfolio[index],
        "futureAllocation",
        allocation.currentAllocation
      );
    });

    updateAllocation(
      this.localPortfolio
        .filter((a) => a.futureAllocation > 0)
        .map((a) => ({
          id: a.id,
          allocation: a.futureAllocation,
        })),
      "futureAllocation"
    );
  }

  public setYearTarget(value: string): void {
    setFutureTargetYear(value);
  }

  public setMarketValue(value: string): void {
    const numberValue = parseInt(value);

    setMarketValue(numberValue);
  }

  public get ui(): any {
    return ui;
  }

  public get allAllocationsDataEntered(): boolean {
    return getters.allAllocationsDataEntered();
  }

  public get marketValue(): number {
    return this.portfolio.marketValue;
  }
  public get futureAllocationTotal(): number {
    return this.portfolio.futureAllocationTotal;
  }

  public get currentAllocationTotal(): number {
    return this.portfolio.currentAllocationTotal;
  }

  public get futureTargetYear(): string {
    return this.portfolio.futureTargetYear;
  }

  @Watch("this.$route.params.profileId", { immediate: true })
  updateLocalPortfolioFromProp(updatedPortfolio: any): void {
    if (!updatedPortfolio) return;
    this.updateLocalPortfolio();
  }

  @Watch("isProfileChange", { immediate: true })
  updateShowWarning(isChange: boolean): void {
    if (!isChange) return;
    this.setShowAllocationWarning();
  }
}
