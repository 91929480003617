












import { Component, Vue } from "vue-property-decorator";
@Component
export default class BaseModel extends Vue {
  public closeModal() {
    this.$store.commit("closeModal", null);
  }

  get modalContent() {
    return this.$store.state.modalContent;
  }
}
