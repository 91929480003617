import Vue from "vue";

export const ui = Vue.observable({
  allocationSlideOutInView: false,
  legendSlideOutInView: false,
  showPopUp: false,
  allocationMessageOverlay: false,
  showTerms: false,
  showTierColumn: false,
  showTierChangedMessage: false
});


export const showTier = () => {
  ui.showTierColumn = true;
}

export const showTierChangedMessage = (isShowMessage) => {
  ui.showTierChangedMessage = isShowMessage;
}


export const openAllocationSlideOut = (): void => {
  ui.allocationSlideOutInView = true;
};

export const closePopUp = (): void => {
  ui.showPopUp = false;
};

export const showAllocationMessageOverlay = (): void => {
  ui.allocationMessageOverlay = true;
};
export const closeAllocationMessageOverlay = (): void => {
  ui.allocationMessageOverlay = false;
};

export const setSlideOutByKey = (
  sliderKey: string,
  showHide: boolean
): void => {
  ui[sliderKey] = showHide;
};

export const closeAllocationSlideOut = () => {
  ui.allocationSlideOutInView = false;
};

export const showTerms = (): void => {
  ui.showTerms = true;
};
export const closeTerms = (): void => {
  ui.showTerms = false;
};
