import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    emissionPathway: [],
    potentialAlignment: [],
    portionChart: [],
    modalContent: null,
  },
  mutations: {
    updateEmissionPathway(state, emissionPathwayData) {
      Vue.set(state, "emissionPathway", emissionPathwayData);
    },
    updatePotentialAlignment(state, potentialAlignmentData) {
      Vue.set(state, "potentialAlignment", potentialAlignmentData);
    },
    updatePortionChart(state, portionChartData) {
      Vue.set(state, "portionChart", portionChartData);
    },

    updateModalContent(state, content) {
      Vue.set(state, "modalContent", content);
    },
    closeModal(state, content) {
      Vue.set(state, "modalContent", content);
    },
  },
  actions: {},
  modules: {},
});
