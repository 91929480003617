
















































































import { Component, Vue,  Watch} from "vue-property-decorator";
import { RouteRecord } from "vue-router";

@Component
export default class Header extends Vue {
  public showPathwayPrompt = false;

  public get activeRoute(): string {
    if (this.$route.name === null) return "";
    return this.$route.name;
  }

  public get profileId(): string {
    return this.$route?.params?.profileId;
  }

  private pathwayPrompt(): void{
    const pathwayPrompt =  window.localStorage.getItem('pathwayPrompt');
    if(pathwayPrompt === null){
      setTimeout(()=> {
        this.showPathwayPrompt = true
      },5000)
    }
  }

  public closeAndSetPrompt():void {
    this.showPathwayPrompt = false;
    window.localStorage.setItem('pathwayPrompt', 'true')
  }

  @Watch('activeRoute')
  updateActiveRoute(route: string) {
    if(route === 'ClimateRisk') this.pathwayPrompt()

  }



  get reducedHeaderClass() {
    return this.activeRoute === 'Pathway' || this.activeRoute === 'ClimateRisk'
  }
}
