




























































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { getProfileById } from '@/observables/profiles';
import { modelData, activePortfolio, setMarketValue, setFutureTargetYear, getters, updateAllocation, setPortfolioInView, setEvovleStrategy, setCurrency, updateTiers, resetTier, getChangedFromStandard } from '@/observables/portfolios';
import { ui } from '@/observables/ui';
import TextInput from '@/components/inputs/TextInput.vue';
import InfoIcon from '@/components/UI/InfoIcon.vue';
import InfoIconToolTip from '@/components/UI/InfoIconToolTip.vue';

@Component({
  components: {
    TextInput,
    InfoIconToolTip,
    InfoIcon,
  },
})
export default class Allocations extends Vue {
  @Prop(Boolean) isProfileChange!: boolean;
  // public id= '';
  public isFutureEqCurrent = false;
  public isCustomisable = false;
  public currencyOption = '£';
  public showAllocationWarning = false;
  public dates = [];
  public localPortfolio: any = [];
  public componentKey = 0;

  public selectedTier = '';
  public tierNumber = [1, 2, 3, 4, 5];

  public getAndSetProfile(): void {
    const profile = getProfileById(this.$route.params.profileId);
    this.setPortfolioInView(profile);
  }

  public setCurrencyValue(value: string) {
    setCurrency(value);
  }

  public setPortfolioInView(profile: any): void {
    setPortfolioInView(profile, true);
  }

  private setMarketValueSelection(): void {
    if (this.futureAllocationTotal === 0) {
      setEvovleStrategy('No');
    } else {
      setEvovleStrategy('Yes');
    }
  }

  public created(): void {
    this.getAndSetProfile();
    this.setMarketValueSelection();
    if (this.$route.params.profileId) this.updateLocalPortfolio();
  }

  public updateLocalPortfolio() {
    this.localPortfolio = [];
    const assetClasses = modelData.universeData.map((asset) => asset.subCategories).flat();
    let assetC = '';
    assetClasses.forEach((c) => {
      const currentAllocation = this.portfolio.currentAllocation.find((a) => a.id === c.id);
      const futureAllocation = this.portfolio.futureAllocation?.find((a) => a.id === c.id);
      this.localPortfolio.push({
        id: c.id,
        assetClass: assetC == c.class ? '' : c.class,
        subClass: c.name,
        tier: c.tier,
        lcpDefaultTier: c.lcpDefaultTier,
        currentAllocation: currentAllocation ? currentAllocation.allocation : 0,
        futureAllocation: futureAllocation ? futureAllocation.allocation : 0,
      });
      assetC = c.class;
    });
  }

  public updateEvolve(value: string) {
    setEvovleStrategy(value);
  }
  public checkToCustomise(): void {
    this.showAllocationWarning = true;
    this.isCustomisable = true;
  }

  public setToCustomAllocation(): void {
    this.showAllocationWarning = false;
    this.$emit('setToCustomiseAllocation');
    this.isCustomisable = false;
    //
  }
  public setShowAllocationWarning() {
    const { name } = this.$route;
    const isPathway = name === 'Pathway' && this.portfolio.futureAllocationTotal < 1;
    if (this.portfolio.name === 'Your allocation') {
      this.showAllocationWarning = true;
    }
    if (isPathway) {
      this.showAllocationWarning = true;
    }
  }

  public closeAllocationOverlay(): void {
    this.showAllocationWarning = false;
  }

  public setAllocation(value: string, id: string, property: string, index: number): void {
    let numberValue: number;

    if (value === '') {
      numberValue = 0;
    } else {
      numberValue = parseFloat(value);
    }

    if (!this.isValid(value)) {
      numberValue = 0;
    }

    if (isNaN(numberValue)) {
      Vue.set(this.localPortfolio[index], property, this.localPortfolio[index][property]);
      this.componentKey += 1;
      return;
    } else {
      Vue.set(
        this.localPortfolio.find((a) => a.id === id),
        property,
        numberValue
      );
    }

    if (numberValue === 0) {
      Vue.set(this.localPortfolio[index], property, this.localPortfolio[index][property]);
      this.componentKey += 1;
    }

    updateAllocation(
      this.localPortfolio
        .filter((a) => a.currentAllocation > 0)
        .map((a) => ({
          id: a.id,
          allocation: a.currentAllocation,
        })),
      'currentAllocation'
    );

    updateAllocation(
      this.localPortfolio
        .filter((a) => a.futureAllocation > 0)
        .map((a) => ({
          id: a.id,
          allocation: a.futureAllocation,
        })),
      'futureAllocation'
    );
  }

  public updateLocalTier(value: string, id: string, index: number) {
    Vue.set(this.localPortfolio[index], 'tier', parseInt(value));
    updateTiers(id, value);
  }

  public resetTiers() {
    resetTier();
    this.updateLocalPortfolio();
  }

  public copyCurrentAllocation() {
    this.localPortfolio.forEach((allocation, index) => {
      Vue.set(this.localPortfolio[index], 'futureAllocation', allocation.currentAllocation);
    });

    updateAllocation(
      this.localPortfolio
        .filter((a) => a.futureAllocation > 0)
        .map((a) => ({
          id: a.id,
          allocation: a.futureAllocation,
        })),
      'futureAllocation'
    );
  }
  public routeToProfile(): void {
    this.$router.push(`/profile/${this.$route.params.profileId}/climate-risk`);
  }

  public setYearTarget(value: string): void {
    setFutureTargetYear(value);
  }

  public setMarketValue(value: string): void {
    let numberValue: number;

    if (value === '') {
      numberValue = 0;
    } else {
      numberValue = parseFloat(value);
    }

    if (!this.isValid(value)) {
      numberValue = 0;
    }

    if (isNaN(numberValue)) {
      numberValue = this.marketValue;
      this.componentKey += 1;
    }
    if (numberValue === 0) this.componentKey += 1;

    setMarketValue(numberValue);
  }

  get tiersChangedArray() {
    return this.localPortfolio.filter((portfolio) => {
      return portfolio.tier !== portfolio.lcpDefaultTier;
    });
  }
  get showTierColumn() {
    return ui.showTierColumn;
  }

  private isValid(value: string) {
    const regExp = new RegExp('/[+-]?([0-9]*[.])?[0-9]+/').test(value);
    const numberValue = typeof parseFloat(value) === 'number';

    return numberValue || regExp;
  }
  get portfolio() {
    return activePortfolio;
  }

  get isEvolveAllocation() {
    return activePortfolio.updateEvolve;
  }

  get profileType(): string {
    return this.portfolio.type;
  }

  get assetClasss(): any {
    return modelData.universeData.map((asset) => asset.subCategories).flat();
  }

  public get yearsArray(): string[] {
    const years = [];
    let startYear = new Date().getFullYear();
    years.push(startYear.toString());
    for (let i = 0; i < 30; i++) {
      if (startYear < modelData.maxYearPathways) years.push((startYear += 1).toString());
    }
    return years;
  }

  public get ui(): any {
    return ui;
  }

  public get allAllocationsDataEntered(): boolean {
    return getters.allAllocationsDataEntered();
  }

  public get marketValue(): number {
    return this.portfolio.marketValue;
  }
  public get futureAllocationTotal(): number {
    return this.portfolio.futureAllocationTotal;
  }

  public get currentAllocationTotal(): number {
    return this.portfolio.currentAllocationTotal;
  }

  public get futureTargetYear(): string {
    return this.portfolio.futureTargetYear;
  }

  @Watch('isEvolveAllocation')
  updatefutureAllocationValues(isEvolveFutureAllocation: boolean): void {
    if (!isEvolveFutureAllocation) return;
    this.copyCurrentAllocation();
  }

  @Watch('isProfileChange', { immediate: true })
  updateShowWarning(isChange: boolean): void {
    if (!isChange) return;
    this.setShowAllocationWarning();
  }
}
