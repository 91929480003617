










































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { getProfileById } from '@/observables/profiles';
import { activePortfolio, getters, modelData, setEvovleStrategy, setPortfolioView, setPathwayView, updateAlignmentRate } from '@/observables/portfolios';
import { setSlideOutByKey } from '@/observables/ui';
import AllocationsTable from '@/components/AllocationsTable.vue';
import SlideOut from '@/components/SlideOut.vue';
import InfoIcon from '@/components/UI/InfoIcon.vue';

import { pathwaysInfo } from '@/data/infoTextContent';

@Component({
  components: {
    SlideOut,
    AllocationsTable,
    InfoIcon,
  },
})
export default class Pathway extends Vue {
  public showAlignmentModal = false;
  public currentHoverIndex = null;
  public tabActive = null;
  public profileDetails = {};
  public currentChart = null;
  public evolveAllocation = 'Yes';
  public lookAtValue = 'All excluding gilts';
  public chartIndex1 = 0;
  public chartIndex2 = 0;
  public slowAlignment = '3%';
  public fastAlignment = '6%';
  public pathwayView = 'normal';

  public pathwaysInfo = pathwaysInfo;

  public showAlignModalContent(): void {
    this.showAlignmentModal = true;
  }

  public closeModal(): void {
    this.showAlignmentModal = false;
  }

  mounted(): void {
    this.currentChart = 0;
    this.switchChart(this.currentChart);
  }

  public chartOptions = {};

  switchChart(chartNum: number | null): void {
    this.currentChart = chartNum;
  }

  updateEvolve() {
    setEvovleStrategy(this.evolveAllocation);
  }

  updatePartPortfolio() {
    setPortfolioView(this.lookAtValue);
  }

  updatePathwayView(view: string) {
    this.pathwayView = view;
    setPathwayView(view);
  }

  updateAlignment(ty: string) {
    if (ty === 'slow') {
      updateAlignmentRate(ty, Number(this.slowAlignment.substring(0, this.slowAlignment.length - 1)) / 100);
    } else {
      updateAlignmentRate(ty, Number(this.fastAlignment.substring(0, this.fastAlignment.length - 1)) / 100);
    }
  }

  public get allAllocationsDataEntered(): boolean {
    return getters.allAllocationsDataEntered();
  }

  public get setContentRadius(): string {
    if (this.currentChart === 0) return 'active-content content-1-radius';
    if (this.currentChart === 1) return 'active-content content-2-radius';
    return '';
  }

  public get setChartHolderBorderRadius(): string {
    return this.currentChart === 0 ? 'border-radius-br' : 'border-radius-tr';
  }

  public get portfolioInView(): any | null {
    return activePortfolio;
  }

  public get yearOneCarbon(): number {
    // return this.pathwayView == 'decarbonised' ? activePortfolio.calculationResults.pathways.yearOneCarbonDecarbon : activePortfolio.calculationResults.pathways.yearOneCarbon;
    return activePortfolio.calculationResults.pathways.yearOneCarbon;
  }

  public get CarsOnRoad(): number {
    return this.yearOneCarbon / modelData.transitionalChanges.comparisonData.co2percar;
  }

  public get AnnualCost(): number {
    return this.yearOneCarbon * modelData.transitionalChanges.comparisonData.costperton;
  }

  public get OneTonneCost(): number {
    return modelData.transitionalChanges.comparisonData.costperton;
  }

  public get emissionPathway(): any {
    let chartData = [];

    chartData.push({
      name: 'approximate 2030 Paris target',
      data: activePortfolio.calculationResults.pathways.target2030,
      type: 'column',
      color: 'rgba(0,163,199,0.5)',
    });

    let startD = {};
    let max = 0;
    let len = 0;

    for (var n in activePortfolio.calculationResults.pathways.carbonScenarios) {
      chartData.push({
        name: n,
        data: this.pathwayView == 'decarbonised' ? activePortfolio.calculationResults.pathways.carbonScenariosDecarbon[n] : activePortfolio.calculationResults.pathways.carbonScenarios[n],
        color: modelData.transitionalChanges.categories[n].color,
      });

      let maxD =  Math.max.apply(null, activePortfolio.calculationResults.pathways.carbonScenarios[n]);
      len = activePortfolio.calculationResults.pathways.carbonScenarios[n].length;

      if (maxD > max) {
        max = maxD;
      }

      startD[n] = this.pathwayView == 'decarbonised' ? activePortfolio.calculationResults.pathways.carbonScenariosDecarbon[n][0] : activePortfolio.calculationResults.pathways.carbonScenarios[n][0];
    }

    let maxArray = new Array(len).fill(max);

    chartData.push({
      name: 'max',
      data: maxArray,
      color: 'rgba(255,255,255,0)',
      showInLegend: false,
    });

    const chartOptions = {
      chart: {
        type: 'line',
        height: 370,
      },
      title: {
        text: `Your Emissions Pathways - ${this.lookAtValue}`,
      },
      yAxis: {
        title: {
          text: 'tonnes of CO2 per year',
        },
      },
      tooltip: {
        formatter: function () {
          let s = '';
          this.points.forEach((point) => {
            s = s + (point.series.name != 'approximate 2030 Paris target' &&  point.series.name != 'max' ? `<span style="color:${point.series.color}">${point.series.name}</span>: <b>${point.y.toFixed(0)} tonnes</b> (change ${((point.y / startD[point.series.name] - 1) * 100).toFixed(0)}%)<br/>` : '');
            //     return s + '<br/>' + point.series.name + ': ' +
            //         point.y + 'm';
            // }, '<b>' + this.x + '</b>');
          });
          return s;
        },
        // pointFormat:
        //   '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:.0f} tonnes ({point.change:.0f} )</b><br/>',
        shared: true,
      },
      xAxis: {
        categories: activePortfolio.calculationResults.projectionYears,
      },
      credits: {
        enabled: false,
      },
      series: chartData,
    };
    this.chartIndex1++;
    return chartOptions;
  }

  public get alignmentPathway(): any {
    let chartData = [];

    for (var n in activePortfolio.calculationResults.pathways.alignmentScenarios) {
      chartData.push({
        name: n,
        data: activePortfolio.calculationResults.pathways.alignmentScenarios[n],
        color: modelData.alignmentData[n].color,
      });
    }

    const chartOptions = {
      chart: {
        type: 'area',
        height: 330,
      },
      title: {
        text: `Your Alignment Pathway - ${this.lookAtValue}`,
      },
      yAxis: {
        title: {
          text: 'Percentage of Portfolio',
        },
        labels: {
          format: '{value}%',
        },
      },
      xAxis: {
        categories: activePortfolio.calculationResults.projectionYears,
      },
      tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.percentage:.1f}%</b><br/>',
        split: true,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        area: {
          stacking: 'percent',
          lineColor: '#ffffff',
          lineWidth: 1,
          marker: {
            enabled: false,
            lineWidth: 1,
            lineColor: '#ffffff',
          },
        },
      },
      series: chartData,
    };
    this.chartIndex2++;
    return chartOptions;
  }

  public get pointerTop(): number {
    if (this.currentChart === 0) return 0;
    if (this.$mq === 'md' && this.currentChart === 1) return 100;
    // if (this.$mq === "mdLandscape" && this.currentChart === 1) return 150;
    if (this.currentChart === 1) return 150;

    return 0;
  }

  // @Watch("allAllocationsDataEntered", { immediate: true })
  // triggerSlideOut(allDataEntered: boolean): void {
  //   if (!allDataEntered) {
  //     setSlideOutByKey("allocationSlideOutInView", !allDataEntered);
  //   }
  // }
}
