import { render, staticRenderFns } from "./AllocationsTable.vue?vue&type=template&id=2e217e27&scoped=true&"
import script from "./AllocationsTable.vue?vue&type=script&lang=ts&"
export * from "./AllocationsTable.vue?vue&type=script&lang=ts&"
import style0 from "./AllocationsTable.vue?vue&type=style&index=0&id=2e217e27&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e217e27",
  null
  
)

export default component.exports